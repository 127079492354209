import React from "react"
import dynamic from "next/dynamic"

const IconTikTok = dynamic(() => import("./icon-tiktok"), { ssr: false })
const IconTwitter = dynamic(() => import("./icon-twitter"), { ssr: false })
const IconFacebook = dynamic(() => import("./icon-facebook"), { ssr: false })
const IconInstagram = dynamic(() => import("./icon-instagram"), { ssr: false })
const IconGooglePlayStore = dynamic(() => import("./icon-google-play-store"), { ssr: false })
const IconAppleStore = dynamic(() => import("./icon-apple-store"), { ssr: false })
const IconNandos = dynamic(() => import("./icon-nandos"), { ssr: false })
const IconNandosOutline = dynamic(() => import("./icon-nandos-outline"), { ssr: false })
const IconNandosMobile = dynamic(() => import("./icon-nandos-mobile"), { ssr: false })
const IconTime = dynamic(() => import("./icon-time"), { ssr: false })
const IconAlarm = dynamic(() => import("./icon-alarm"), { ssr: false })
const IconOrderAtTable = dynamic(() => import("./icon-order-at-table"), { ssr: false })
const IconCatering = dynamic(() => import("./icon-catering"), { ssr: false })
const IconShoppingBag = dynamic(() => import("./icon-shopping-bag"), { ssr: false })
const IconPayment = dynamic(() => import("./icon-payment"), { ssr: false })
const IconTransfer = dynamic(() => import("./icon-transfer"), { ssr: false })
const IconArticle = dynamic(() => import("./icon-article"), { ssr: false })
const IconDine = dynamic(() => import("./icon-dine"), { ssr: false })
const IconCloseCircleFill = dynamic(() => import("./icon-close-circle-fill"), { ssr: false })
const Tooltip = dynamic(() => import("../tooltips/tooltip"), { ssr: false })

function IconType({
  type,
  tooltip,
  name,
  url,
  classList,
  trackingEvent,
  trackingName,
  trackingPromoId,
}) {
  return (
    <React.StrictMode>
      {(type === "google-play-store" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconGooglePlayStore
              classList={`${classList} relative`}
              url={url || null}
              trackingEvent={trackingEvent || null}
              trackingName={trackingName || null}
              trackingPromoId={trackingPromoId || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "GooglePlay Store Icons" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconGooglePlayStore
              classList={`${classList} relative`}
              url={url || null}
              trackingEvent={trackingEvent || null}
              trackingName={trackingName || null}
              trackingPromoId={trackingPromoId || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "apple-store" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconAppleStore
              classList={`${classList} relative`}
              url={url || null}
              trackingEvent={trackingEvent || null}
              trackingName={trackingName || null}
              trackingPromoId={trackingPromoId || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "App Store Icon" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconAppleStore
              classList={`${classList} relative`}
              url={url || null}
              trackingEvent={trackingEvent || null}
              trackingName={trackingName || null}
              trackingPromoId={trackingPromoId || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "tiktok" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconTikTok
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "facebook" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconFacebook
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "instagram" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconInstagram
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "twitter" && (
          <div
            className="relative flex items-center"
            data-layer-component={type}
          >
            <IconTwitter
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "nandos-logo" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconNandos classList={`${classList} relative`} url={url || null} />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "nandos-logo-outline" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconNandosOutline
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "nandos-logo-mobile" && (
          <div className="relative" data-layer-component={type}>
            <IconNandosMobile
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "time" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconTime classList={`${classList} relative`} url={url || null} />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "alarm" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconAlarm classList={`${classList} relative`} url={url || null} />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "order-at-table" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconOrderAtTable
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "catering" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconCatering
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "shopping-bag" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconShoppingBag
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "payment" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconPayment
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "transfer" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconTransfer
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "article" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconArticle
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "dine" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconDine classList={`${classList} relative`} url={url || null} />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "patio" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconDine classList={`${classList} relative`} url={url || null} />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "pickup" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconDine classList={`${classList} relative`} url={url || null} />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "parking" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconDine classList={`${classList} relative`} url={url || null} />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        (type === "cross-circle-full" && (
          <div
            className="relative flex items-center justify-center"
            data-layer-component={type}
          >
            <IconCloseCircleFill
              classList={`${classList} relative`}
              url={url || null}
            />
            {name && <p className="mb-0 ml-2px text-sm">{type}</p>}
            {tooltip && <Tooltip content={type} />}
          </div>
        )) ||
        null}
    </React.StrictMode>
  )
}

export default IconType
